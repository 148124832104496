import React from 'react';
import { SpinnerBase } from './Spinner.style';

export interface SpinnerProps {
    color?: string;
    width?: number;
    size?: number;
}

export const Spinner = ({
    size = 36,
    color = 'black',
    width = 5,
}: SpinnerProps): JSX.Element => {
    return (
        <>
            <SpinnerBase width={size} height={size} viewBox="0 0 50 50">
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth={width}
                    stroke={color}
                />
            </SpinnerBase>
        </>
    );
};
