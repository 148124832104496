import { useEffect, useState } from 'react';
import axios from 'axios';
import { FilterWithNameModel } from 'dineout-types/Filter/FilterModel';

const API_URL = process.env.REACT_APP_DINEOUT_API ?? 'http://localhost:9876/';

export const useFetchCategories = () => {
    const [categories, setCategories] = useState<FilterWithNameModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchFilters = async () => {
            setIsLoading(true);

            try {
                const response = await axios.get<{
                    categories: FilterWithNameModel[];
                }>(API_URL + 'api/web/filter/inuse?country=is');

                if (response.status === 200) {
                    setCategories(response.data.categories);
                    return;
                } else {
                    throw new Error(response.statusText);
                }
            } catch (e) {
                console.error(e);
                alert('Error came up fetching filters for Dineout');
                setCategories([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchFilters().then(() => {});
    }, []);

    return {
        categories,
        isLoading,
    };
};
