import axios from 'axios';
import { useDebounce } from 'usehooks-ts';
import { useEffect, useState } from 'react';

const API_URL = process.env.REACT_APP_DINEOUT_API ?? 'http://localhost:9876/';
const API_KEY = process.env.REACT_APP_DINEOUT_PUBLIC_KEY;

export type RestaurantNameModel = { id: number; name: string; slug: string; };

export const useRestaurantSearch = (searchQuery: string, country = 'is') => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<RestaurantNameModel[]>(
        [],
    );
    const [searchValue, setSearchValue] = useState('');

    const debouncedSearchValue = useDebounce(searchQuery, 250);

    useEffect(() => {
        setSearchValue(debouncedSearchValue);
    }, [debouncedSearchValue]);

    useEffect(() => {
        const searchMutation = async () => {
            setSearchResults([]);

            if (searchValue.length <= 0) {
                return;
            }

            try {
                setIsLoading(true);
                const url = `${API_URL}api/public/restaurant/${API_KEY}/${country}/q/${encodeURIComponent(
                    searchValue,
                )}`;

                const res = await axios.get<RestaurantNameModel[]>(url);

                if (res.status === 200) {
                    setSearchResults(res.data);
                    return;
                } else {
                    throw new Error(res.statusText);
                }
            } catch (e) {
                console.error(e);
                alert(e);
            } finally {
                setIsLoading(false);
            }
        };

        searchMutation().then(() => {});
    }, [searchValue, country]);

    return {
        isLoading,
        data: searchResults,
    };
};
