import React from 'react';
import { CardContainer, CardContent, CardTitle } from './Card.style';

interface CardProps {
  title?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const Card: React.FC<CardProps> = ({ title, children, style }) => {
  return (
    <CardContainer style={style}>
      {title && <CardTitle>{title}</CardTitle>}
      <CardContent>{children}</CardContent>
    </CardContainer>
  );
};
