import React from 'react';
import { RestaurantCategory, RestaurantCategoryDev } from './extensions/RestaurantCategory';
import { Route, Routes } from 'react-router-dom';
import { ItemCategory, ItemCategoryDev } from './extensions/ItemCategory';
import { HappyHourCard, HappyHourCardDev } from './extensions/HappyHourCard';
import { RestaurantModelCard, RestaurantModelCardDev } from './extensions/RestaurantModel';
import { EventSelector, EventSelectorDev } from './extensions/EventSelector';
import { CategorySelector, CategorySelectorDev } from './extensions/CategorySelector';

export const App = () => (
  <Routes>
    <Route index element={<h1>Oh, hi Mark! 👋</h1>} />
    <Route path={'restaurant-category'} element={<RestaurantCategory />} />
    <Route path={'restaurant-category-dev'} element={<RestaurantCategoryDev />} />
    <Route path={'search-single-restaurant'} element={<RestaurantModelCard />} />
    <Route path={'search-single-restaurant-dev'} element={<RestaurantModelCardDev />} />
    <Route path={'happy-hour-card'} element={<HappyHourCard />} />
    <Route path={'happy-hour-card-dev'} element={<HappyHourCardDev />} />

    <Route path={'item-category'} element={<ItemCategory />} />
    <Route path={'item-category-dev'} element={<ItemCategoryDev />} />

    <Route path={'event'} element={<EventSelector />} />
    <Route path={'event-dev'} element={<EventSelectorDev />} />

    <Route path={'category-selector'} element={<CategorySelector />} />
    <Route path={'category-selector-dev'} element={<CategorySelectorDev />} />
  </Routes>
);
