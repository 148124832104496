import { useEffect, useState } from 'react';
import axios from 'axios';
import { OrganizationModelWithMeta } from 'dineout-types/Organization/OrganizationModel';

const API_URL = process.env.REACT_APP_DINEOUT_API ?? 'http://localhost:9876/';

export const useFetchOrganizations = () => {
  const [organizations, setOrganizations] = useState<OrganizationModelWithMeta[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrganizations = async () => {
      setIsLoading(true);

      try {
        const response = await axios.get<OrganizationModelWithMeta[]>(API_URL + 'api/web/organization');

        if (response.status === 200) {
          setOrganizations(response.data);
          return;
        } else {
          throw new Error(response.statusText);
        }
      } catch (e) {
        console.error(e);
        alert('Error came up fetching organizations for Dineout');
        setOrganizations([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganizations().then(() => {});
  }, []);

  return {
    data: organizations,
    isLoading,
  };
};
