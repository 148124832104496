import axios from 'axios';
import { useDebounce } from 'usehooks-ts';
import { useEffect, useState } from 'react';
import { ItemType } from 'dineout-hygraphcms/types/extensionTypes/ItemCategoryTypes';

const API_URL = process.env.REACT_APP_DINEOUT_API ?? 'http://localhost:9876/';
const API_KEY = process.env.REACT_APP_DINEOUT_PUBLIC_KEY;

export type ItemNameModel = {
  id: number;
  name: string;
  restaurantId: number;
  restaurantName: string;
};

export const useItemSearch = (searchQuery: string, itemType: ItemType, country = 'is') => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<ItemNameModel[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebounce(searchQuery, 250);

  useEffect(() => {
    setSearchValue(debouncedSearchValue);
  }, [debouncedSearchValue]);

  useEffect(() => {
    const searchMutation = async () => {
      setSearchResults([]);

      if (searchValue.length <= 0) {
        return;
      }

      try {
        setIsLoading(true);
        const url = `${API_URL}api/public/item/${API_KEY}/${country}/q/?searchQuery=${encodeURIComponent(
          searchValue,
        )}&itemType=${itemType}`;

        const res = await axios.get<ItemNameModel[]>(url);

        if (res.status === 200) {
          setSearchResults(res.data);
          return;
        } else {
          throw new Error(res.statusText);
        }
      } catch (e) {
        console.error(e);
        alert(e);
      } finally {
        setIsLoading(false);
      }
    };

    searchMutation().then(() => {});
  }, [searchValue, itemType, country]);

  return {
    isLoading,
    data: searchResults,
  };
};
