import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ExtensionDeclaration,
  FieldExtensionFeature,
  FieldExtensionType,
  useFieldExtension,
  Wrapper,
} from '@graphcms/uix-react-sdk';
import { Spinner } from '../components/Spinner';
import { AddButton, H3, InputStyle, RemoveSpan, UL } from 'src/components/Components.style';
import { ItemNameModel } from '../hooks/useItemSearch';
import { useEventSearch } from '../hooks/useEventSearch';
import { CountrySelector } from '../components/CountrySelector';
import { DineoutRegion } from 'dineout-hygraphcms/types/graphTypes';

const declaration: ExtensionDeclaration = {
  name: 'Event Category',
  extensionType: 'field',
  fieldType: FieldExtensionType.JSON,
  features: [FieldExtensionFeature.FieldRenderer, FieldExtensionFeature.TableRenderer],
};

// TODO MATCH WITH MarketplaceRestaurantsRequest when Types are working cross packages
type EventCategoryValue = {
  items: { name: string; id: number }[];
};

export const EventSelectorFields = () => {
  const { value, onChange } = useFieldExtension();
  const [country, setCountry] = useState<DineoutRegion>('is' as DineoutRegion);
  const [search, setSearch] = useState<string>('');
  const [items, setItems] = useState<ItemNameModel[]>(value?.items ?? []);

  const { isLoading, data } = useEventSearch(search, country);

  const toggle = useCallback(
    (r: ItemNameModel) => {
      if (items.findIndex((rn) => rn.id === r.id) > -1) {
        setItems(items.filter((rn) => rn.id !== r.id));
      } else {
        setItems([...items, r]);
      }
    },
    [items],
  );

  useEffect(() => {
    if (onChange && typeof onChange === 'function') {
      const itemIds = items.map((r) => {
        return {
          id: r.id,
          name: r.name,
        };
      });

      const data: EventCategoryValue = {
        items: itemIds,
      };

      onChange(data);
    }
  }, [items]);

  // Don't show the same items in the selection
  const unselectedItems = useMemo(() => {
    const itemIds = items.map((r) => r.id);
    return data.filter((d) => !itemIds.includes(d.id));
  }, [data, items]);

  return (
    <>
      <div style={{ padding: 8 }}>
        <H3 style={{ marginTop: 16 }}>Select country</H3>
        <div>
          <CountrySelector value={country} onChange={(v) => setCountry(v)} />
        </div>
        <H3 style={{ marginTop: 16 }}>Select the event you want to see in this page block.</H3>
        <div style={{ padding: `8px 0`, marginBottom: 8 }}>
          <InputStyle
            placeholder={'Type to search for a event'}
            type={'text'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: 16 }}>
          {isLoading ? (
            <Spinner />
          ) : data.length === 0 ? (
            <p
              style={{
                opacity: 0.6,
                fontStyle: 'italic',
              }}
            >
              Start typing to get some results
            </p>
          ) : (
            unselectedItems.map((r) => (
              <AddButton key={r.id} onClick={() => toggle(r)}>
                Add {r.name} - {r.restaurantName}
              </AddButton>
            ))
          )}
        </div>
        <H3>Selected events:</H3>
        <div style={{ padding: `8px 0`, marginTop: 16 }}>
          <UL>
            {items.map((r) => (
              <li key={r.id}>
                {r.name}
                <RemoveSpan onClick={() => toggle(r)}>Remove</RemoveSpan>
              </li>
            ))}
          </UL>
        </div>
      </div>
    </>
  );
};

export const EventSelector = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const uid = queryParams.get('extensionUid') ?? '';

  return (
    <Wrapper uid={uid} declaration={declaration}>
      <EventSelectorFields />
    </Wrapper>
  );
};

export const EventSelectorDev = () => {
  return <EventSelectorFields />;
};
