import React, { useCallback, useMemo, useState } from 'react';
import {
  ExtensionDeclaration,
  FieldExtensionFeature,
  FieldExtensionType,
  useFieldExtension,
  Wrapper,
} from '@graphcms/uix-react-sdk';
import { useFetchCategories } from '../hooks/useFetchCategories';
import { Spinner } from '../components/Spinner';
import { AddButton, H3, InputStyle, RemoveSpan, SelectStyle, UL } from 'src/components/Components.style';
import { ItemNameModel, useItemSearch } from '../hooks/useItemSearch';
import { FilterWithNameModel } from 'dineout-types/Filter/FilterModel';
import { CountrySelector } from '../components/CountrySelector';
import { DineoutRegion } from 'dineout-hygraphcms/types/graphTypes';

const declaration: ExtensionDeclaration = {
  name: 'Restaurant Category',
  extensionType: 'field',
  fieldType: FieldExtensionType.JSON,
  features: [FieldExtensionFeature.FieldRenderer, FieldExtensionFeature.TableRenderer],
};

const DEFAULT_VALUE = 'trending';
const DEFAULT_ITEM_TYPE_VALUE = 'item';

export const ItemCategoryFields = ({ categories }: { categories: FilterWithNameModel[] }) => {
  const { value, onChange } = useFieldExtension();

  const initialValue: typeof value & { items: ItemNameModel[] } = {
    id: value?.id ?? DEFAULT_VALUE,
    type: value?.type ?? DEFAULT_ITEM_TYPE_VALUE,
    items: value?.items || [],
    itemIds: value?.itemIds ?? [],
  };

  const [currentValue, setCurrentValue] = useState(initialValue);
  const [country, setCountry] = useState<DineoutRegion>('is' as DineoutRegion);
  const [search, setSearch] = useState<string>('');
  const { isLoading, data } = useItemSearch(search, currentValue.type, country);

  const toggle = useCallback(
    (item: ItemNameModel) => {
      const updatedItems = currentValue.items.some((i: ItemNameModel) => i.id === item.id)
        ? currentValue.items.filter((i: ItemNameModel) => i.id !== item.id)
        : [...currentValue.items, item];

      const updatedValue = {
        ...currentValue,
        items: updatedItems,
        itemIds: updatedItems.map((i: ItemNameModel) => i.id),
      };

      setCurrentValue(updatedValue);
      if (onChange) {
        onChange({
          id: updatedValue.id,
          type: updatedValue.type,
          itemIds: updatedValue.items.map((i: ItemNameModel) => i.id),
          items: updatedValue.items,
        });
      }
    },
    [currentValue, onChange],
  );

  const handleBlockTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const updatedValue = {
      ...currentValue,
      id: e.target.value,
    };
    setCurrentValue(updatedValue);
    if (onChange) {
      onChange({
        id: updatedValue.id,
        type: updatedValue.type,
        itemIds: updatedValue.items.map((i: ItemNameModel) => i.id),
      });
    }
  };

  const handleItemTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const updatedValue = {
      ...currentValue,
      type: e.target.value,
    };
    setCurrentValue(updatedValue);
  };

  // Don't show the same items in the selection
  const unselectedItems = useMemo(() => {
    return data.filter((d) => !currentValue.itemIds.includes(d.id));
  }, [data, currentValue]);

  const renderSelectedItems = () => {
    if (currentValue.items.length > 0) {
      return currentValue.items.map((item: ItemNameModel) => (
        <li key={item.id}>
          <b>Restaurant:</b> {item.restaurantName} <br /> <b>Item Name:</b> {item.name}
          <RemoveSpan onClick={() => toggle(item)}>Remove</RemoveSpan>
        </li>
      ));
    } else {
      return currentValue.itemIds.map((id: number) => (
        <li key={id}>
          Item with ID: {id}
          <RemoveSpan onClick={() => toggle({ id, name: `Item with ID: ${id}`, restaurantName: '', restaurantId: 0 })}>
            Remove
          </RemoveSpan>
        </li>
      ));
    }
  };

  return (
    <>
      <div style={{ padding: 8 }}>
        <H3 style={{ marginTop: 16 }}>Select country</H3>
        <div>
          <CountrySelector value={country} onChange={(v) => setCountry(v)} />
        </div>
        <H3>Select block type</H3>
        <div style={{ padding: `8px 0`, position: 'relative' }}>
          <SelectStyle value={currentValue.id} onChange={handleBlockTypeChange}>
            <option value="trending">Trending items</option>
            <option value="new">New items</option>
            <option value="handpicked">Manually select items</option>
            {categories.map((c) => (
              <option value={c.id} key={c.id}>
                {c.name}
              </option>
            ))}
          </SelectStyle>
        </div>

        <H3>Item type</H3>
        <div style={{ padding: `8px 0`, position: 'relative' }}>
          <SelectStyle value={currentValue.type} onChange={handleItemTypeChange}>
            <option value="item">Items</option>
            <option value="giftCard">Gift Cards</option>
            <option value="itemAndGiftCard">Items and Gift Cards</option>
          </SelectStyle>
        </div>
        {currentValue.id === 'handpicked' && (
          <>
            <H3 style={{ marginTop: 16 }}>Select the items you want to see in this page block.</H3>
            <div style={{ padding: `8px 0`, marginBottom: 8 }}>
              <InputStyle
                placeholder={'Type to search for a item'}
                type={'text'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div style={{ marginBottom: 16 }}>
              {isLoading ? (
                <Spinner />
              ) : data.length === 0 ? (
                <p
                  style={{
                    opacity: 0.6,
                    fontStyle: 'italic',
                  }}
                >
                  Start typing to get some results
                </p>
              ) : (
                unselectedItems.map((r) => (
                  <AddButton key={r.id} onClick={() => toggle(r)}>
                    Add {r.name} - {r.restaurantName}
                  </AddButton>
                ))
              )}
            </div>
            <H3>Selected items:</H3>
            <div style={{ padding: `8px 0`, marginTop: 16 }}>
              <UL>{renderSelectedItems()}</UL>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const ItemCategory = () => {
  const { isLoading, categories } = useFetchCategories();
  const queryParams = new URLSearchParams(window.location.search);
  const uid = queryParams.get('extensionUid') ?? '';

  //ROSO UID
  return (
    <Wrapper uid={uid} declaration={declaration}>
      {isLoading ? <Spinner /> : <ItemCategoryFields categories={categories} />}
    </Wrapper>
  );
};

export const ItemCategoryDev = () => {
  const { isLoading, categories } = useFetchCategories();

  if (isLoading) {
    return <Spinner />;
  }

  return <ItemCategoryFields categories={categories} />;
};
