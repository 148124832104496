import React, { useMemo, useState } from 'react';
import {
  ExtensionDeclaration,
  FieldExtensionFeature,
  FieldExtensionType,
  useFieldExtension,
  Wrapper,
} from '@graphcms/uix-react-sdk';
import { Spinner } from '../components/Spinner';
import { AddButton, GridItem, H3, InputStyle } from 'src/components/Components.style';
import { RestaurantNameModel, useRestaurantSearch } from '../hooks/useRestaurantSearch';
import { CountrySelector } from '../components/CountrySelector';
import { DineoutRegion } from 'dineout-hygraphcms/types/graphTypes';

const declaration: ExtensionDeclaration = {
  name: 'Find a restaurant',
  extensionType: 'field',
  fieldType: FieldExtensionType.JSON,
  features: [FieldExtensionFeature.FieldRenderer, FieldExtensionFeature.TableRenderer],
};

export const RestaurantModelField = ({
  value,
  onChange,
}: {
  value: RestaurantNameModel | null;
  onChange: (newValue: RestaurantNameModel) => void;
}) => {
  const [country, setCountry] = useState<DineoutRegion>('is' as DineoutRegion);
  const restaurant = useMemo<RestaurantNameModel | null>(() => {
    try {
      return value ?? null;
    } catch (e) {
      return null;
    }
  }, [value]);
  const [search, setSearch] = useState<string>('');
  const { isLoading, data } = useRestaurantSearch(search, country);

  const toggle = (r: RestaurantNameModel) => {
    if (onChange && typeof onChange === 'function') {
      onChange(r);
    }
  };

  return (
    <>
      <div style={{ padding: 8 }}>
        <H3 style={{ marginTop: 16 }}>Select country</H3>
        <div>
          <CountrySelector value={country} onChange={(v) => setCountry(v)} />
        </div>
        <H3>Find a restaurant</H3>
        <div style={{ padding: `8px 0`, marginBottom: 8 }}>
          <InputStyle
            placeholder={'Type to search for a Restaurant'}
            type={'text'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: 16 }}>
          {isLoading ? (
            <Spinner />
          ) : data.length === 0 ? (
            <p
              style={{
                opacity: 0.6,
                fontStyle: 'italic',
              }}
            >
              Start typing to get some results
            </p>
          ) : (
            data.map((r) => (
              <AddButton key={r.id} onClick={() => toggle(r)}>
                {r.name}
              </AddButton>
            ))
          )}
        </div>
        {restaurant !== null && (
          <>
            <H3>Selected restaurant</H3>
            <div style={{ padding: `8px 0`, marginTop: 16 }}>
              <GridItem key={restaurant.id}>
                <div>
                  <h4 style={{ margin: 0, marginBottom: 8 }}>{restaurant.name}</h4>
                </div>
              </GridItem>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const WrapFunctions = () => {
  const { value, onChange } = useFieldExtension();
  return <RestaurantModelField value={value ?? []} onChange={(newValue) => onChange(newValue)} />;
};

export const RestaurantModelCard = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const uid = queryParams.get('extensionUid') ?? '';

  return (
    <Wrapper uid={uid} declaration={declaration}>
      <WrapFunctions />
    </Wrapper>
  );
};

export const RestaurantModelCardDev = () => {
  const [restaurant, setRestaurant] = useState<RestaurantNameModel | null>(null);
  return <RestaurantModelField value={restaurant} onChange={setRestaurant} />;
};
