import React, { useCallback, useMemo, useState } from 'react';
import {
  ExtensionDeclaration,
  FieldExtensionFeature,
  FieldExtensionType,
  useFieldExtension,
  Wrapper,
} from '@graphcms/uix-react-sdk';
import { Spinner } from '../components/Spinner';
import { AddButton, GridItem, H3, InputStyle, RemoveSpan } from 'src/components/Components.style';
import { useRestaurantSearch } from '../hooks/useRestaurantSearch';
import { HappyHourRestaurant } from 'dineout-types/Restaurant/HappyHourRestaurant';
import { CountrySelector } from '../components/CountrySelector';
import { DineoutRegion } from 'dineout-hygraphcms/types/graphTypes';

const declaration: ExtensionDeclaration = {
  name: 'Happy hour card',
  extensionType: 'field',
  fieldType: FieldExtensionType.JSON,
  features: [FieldExtensionFeature.FieldRenderer, FieldExtensionFeature.TableRenderer],
};

export const HappyHourCardField = ({
  value,
  onChange,
}: {
  value: HappyHourRestaurant[];
  onChange: (newValue: HappyHourRestaurant[]) => void;
}) => {
  const [country, setCountry] = useState<DineoutRegion>('is' as DineoutRegion);
  const restaurants = useMemo<HappyHourRestaurant[]>(() => {
    try {
      const restValue = value ?? [];

      if (!Array.isArray(restValue)) {
        throw new Error('Catch it');
      }

      return restValue;
    } catch (e) {
      return [];
    }
  }, [value]);
  const [search, setSearch] = useState<string>('');
  const { isLoading, data } = useRestaurantSearch(search, country);

  const toggle = useCallback(
    (r: HappyHourRestaurant) => {
      if (onChange && typeof onChange === 'function') {
        if (restaurants.findIndex((rn) => rn.restaurantId === r.restaurantId) > -1) {
          onChange(restaurants.filter((rn) => rn.restaurantId !== r.restaurantId));
        } else {
          onChange([...restaurants, r]);
        }
      }
    },
    [restaurants],
  );

  const setHappyHourStart = useCallback(
    (value: string, r: HappyHourRestaurant) => {
      const newRestaurants = [...restaurants];
      const currentIndex = restaurants.findIndex((res) => res.restaurantId === r.restaurantId);
      newRestaurants[currentIndex] = {
        ...r,
        starts: value,
      };
      onChange(newRestaurants);
    },
    [restaurants],
  );

  const setHappyHourEnd = useCallback(
    (value: string, r: HappyHourRestaurant) => {
      const newRestaurants = [...restaurants];
      const currentIndex = restaurants.findIndex((res) => res.restaurantId === r.restaurantId);
      newRestaurants[currentIndex] = {
        ...r,
        ends: value,
      };
      onChange(newRestaurants);
    },
    [restaurants],
  );

  const unselectedRestaurants = useMemo(() => {
    const restaurantIds = restaurants.map((r) => r.restaurantId);
    return data.filter((d) => !restaurantIds.includes(d.id));
  }, [data, restaurants]);

  return (
    <>
      <div style={{ padding: 8 }}>
        <H3 style={{ marginTop: 16 }}>Select country</H3>
        <div>
          <CountrySelector value={country} onChange={(v) => setCountry(v)} />
        </div>
        <H3>Find happy hour places</H3>
        <div style={{ padding: `8px 0`, marginBottom: 8 }}>
          <InputStyle
            placeholder={'Type to search for a Restaurant'}
            type={'text'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: 16 }}>
          {isLoading ? (
            <Spinner />
          ) : data.length === 0 ? (
            <p
              style={{
                opacity: 0.6,
                fontStyle: 'italic',
              }}
            >
              Start typing to get some results
            </p>
          ) : (
            unselectedRestaurants.map((r) => (
              <AddButton
                key={r.id}
                onClick={() =>
                  toggle({
                    restaurantName: r.name,
                    restaurantId: r.id,
                  })
                }
              >
                Add {r.name}
              </AddButton>
            ))
          )}
        </div>
        <H3>Selections</H3>
        <div style={{ padding: `8px 0`, marginTop: 16 }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
              gridGap: 8,
            }}
          >
            {restaurants.map((r) => (
              <GridItem key={r.restaurantId}>
                <div>
                  <h4 style={{ margin: 0, marginBottom: 8 }}>{r.restaurantName}</h4>
                  <span
                    style={{
                      fontSize: 'smaller',
                      opacity: 0.6,
                    }}
                  >
                    When is the Happy Hour
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      gap: 8,
                      marginTop: 8,
                    }}
                  >
                    <InputStyle
                      type={'time'}
                      placeholder={'HH:MM (Opens at)'}
                      value={r.starts}
                      onChange={(e) => setHappyHourStart(e.target.value, r)}
                      style={{ resize: 'none' }}
                    />
                    <InputStyle
                      type={'time'}
                      placeholder={'HH:MM (Closes at)'}
                      value={r.ends}
                      onChange={(e) => setHappyHourEnd(e.target.value, r)}
                      style={{ resize: 'none' }}
                    />
                  </div>
                  <br />
                </div>
                <RemoveSpan onClick={() => toggle(r)}>Remove</RemoveSpan>
              </GridItem>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export const WrapFunctions = () => {
  const { value, onChange } = useFieldExtension();
  return <HappyHourCardField value={value ?? []} onChange={(newValue) => onChange(newValue)} />;
};

export const HappyHourCard = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const uid = queryParams.get('extensionUid') ?? '';

  return (
    <Wrapper uid={uid} declaration={declaration}>
      <WrapFunctions />
    </Wrapper>
  );
};

export const HappyHourCardDev = () => {
  const [restaurants, setRestaurants] = useState<HappyHourRestaurant[]>([]);
  return <HappyHourCardField value={restaurants} onChange={setRestaurants} />;
};
