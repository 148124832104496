import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ExtensionDeclaration,
  FieldExtensionFeature,
  FieldExtensionType,
  useFieldExtension,
  Wrapper,
} from '@graphcms/uix-react-sdk';
import { useFetchCategories } from '../hooks/useFetchCategories';
import { Spinner } from '../components/Spinner';
import { AddButton, H3, InputStyle, RemoveSpan, SelectStyle, UL } from 'src/components/Components.style';
import { Card } from '../components/Card';
import { RestaurantNameModel, useRestaurantSearch } from '../hooks/useRestaurantSearch';
import { MarketplaceRestaurantsRequest } from 'dineout-types/Restaurant/RestaurantRequest';
import { FilterWithNameModel } from 'dineout-types/Filter/FilterModel';
import { CountrySelector } from '../components/CountrySelector';
import { useFetchOrganizations } from '../hooks/useFetchOrganizations';
import { OrganizationModelWithMeta } from 'dineout-types/Organization/OrganizationModel';
import { OrganizationListItem } from '../components/Card.style';
import { DineoutRegion } from 'dineout-hygraphcms/types/graphTypes';

const declaration: ExtensionDeclaration = {
  name: 'Restaurant Category',
  extensionType: 'field',
  fieldType: FieldExtensionType.JSON,
  features: [FieldExtensionFeature.FieldRenderer, FieldExtensionFeature.TableRenderer],
};

const DEFAULT_VALUE = 'monthly';

type ExtendedMarketplaceRestaurantsRequest = MarketplaceRestaurantsRequest & {
  restaurants: RestaurantNameModel[];
  organization: OrganizationModelWithMeta | null;
};

export const RestaurantCategoryField = ({
  value,
  onChange,
  categories,
}: {
  value: ExtendedMarketplaceRestaurantsRequest;
  onChange: (newValue: ExtendedMarketplaceRestaurantsRequest) => void;
  categories: FilterWithNameModel[];
}) => {
  const [country, setCountry] = useState<DineoutRegion>('is' as DineoutRegion);
  const currentValue = useMemo<ExtendedMarketplaceRestaurantsRequest>(() => {
    try {
      // Just in case Hygraph defaults to some bullshett
      return {
        id: value?.id ?? 'monthly',
        restaurantIds: value?.restaurantIds ?? [],
        restaurants: value?.restaurants ?? [],
        organization: value?.organization ?? null,
      };
    } catch (e) {
      return {
        id: 'monthly',
        restaurantIds: [],
        restaurants: [],
        organization: null,
      };
    }
  }, [value]);

  const [search, setSearch] = useState<string>('');
  const [organizationSearch, setOrganizationSearch] = useState<string>('');
  const { isLoading, data } = useRestaurantSearch(search, country);
  const { isLoading: isLoadingOrganization, data: organizationData } = useFetchOrganizations();
  const [localValue, setLocalValue] = useState(currentValue?.id ?? DEFAULT_VALUE);

  const filteredOrganizations = organizationData
    ? organizationData.filter(
        (org) => org.name.toLowerCase().includes(organizationSearch.toLowerCase()) && !org.isDeleted,
      )
    : [];

  const toggle = useCallback(
    (r: RestaurantNameModel) => {
      if (onChange && typeof onChange === 'function') {
        let restaurants = [...currentValue.restaurants];

        if (restaurants.findIndex((rn) => rn.id === r.id) > -1) {
          restaurants.filter((rn) => rn.id !== r.id);
        } else {
          restaurants = [...restaurants, r];
        }

        onChange({
          ...currentValue,
          id: localValue,
          restaurants: restaurants,
          restaurantIds: restaurants.map((r) => r.id),
        });
      }
    },
    [onChange, localValue, currentValue],
  );

  const toggleOrganization = useCallback(
    (o: OrganizationModelWithMeta | null) => {
      if (onChange && typeof onChange === 'function') {
        onChange({
          ...currentValue,
          id: localValue,
          organization: o,
          restaurantIds: o ? o.restaurants.map((r) => r.restaurantId) : [],
        });
      }
    },
    [onChange, localValue, currentValue],
  );

  useEffect(() => {
    onChange({
      ...currentValue,
      id: localValue,
    });
  }, [localValue]);

  // Don't show the same restaurants in the selection
  const unselectedRestaurants = useMemo(() => {
    const restaurants = currentValue.restaurants;
    const restaurantIds = restaurants.map((r) => r.id);
    return data.filter((d) => !restaurantIds.includes(d.id));
  }, [data, currentValue]);

  return (
    <>
      <div style={{ padding: 8 }}>
        <H3 style={{ marginTop: 16 }}>Select country</H3>
        <div>
          <CountrySelector value={country} onChange={(v) => setCountry(v)} />
        </div>
        <H3>Select block type</H3>
        <div style={{ padding: `8px 0`, position: 'relative' }}>
          <SelectStyle value={localValue} onChange={(e) => setLocalValue(e.target.value)}>
            <option value="monthly">Restaurants of the month</option>
            <option value="organization">Organization</option>
            <option value="handpicked">Manually select restaurants</option>
            {categories.map((c) => (
              <option value={c.id} key={c.id}>
                {c.name}
              </option>
            ))}
          </SelectStyle>
        </div>
        {localValue === 'organization' && (
          <>
            <H3 style={{ marginTop: 16 }}>Select the organization you want to see in this page block.</H3>
            <div style={{ padding: `8px 0`, marginBottom: 8 }}>
              <InputStyle
                placeholder={'Type to search for an Organization'}
                type={'text'}
                value={organizationSearch}
                onChange={(e) => {
                  setOrganizationSearch(e.target.value);
                }}
              />
            </div>
            <div style={{ marginBottom: 16 }}>
              {isLoadingOrganization ? (
                <Spinner />
              ) : !organizationSearch || filteredOrganizations.length === 0 ? (
                <p
                  style={{
                    opacity: 0.6,
                    fontStyle: 'italic',
                  }}
                >
                  Start typing to get some results
                </p>
              ) : (
                filteredOrganizations.map((o) => (
                  <AddButton key={o.id} onClick={() => toggleOrganization(o)}>
                    Add {o.name}
                  </AddButton>
                ))
              )}
            </div>
            <H3>Selected organization:</H3>
            <div style={{ padding: `8px 0`, marginTop: 16 }}>
              {currentValue.organization && (
                <Card title={currentValue.organization.name} style={{ display: 'flex', flexDirection: 'column' }}>
                  <UL>
                    {currentValue.organization.restaurants.map((r) => (
                      <OrganizationListItem key={r.restaurantId}>
                        <h4>{r.restaurantName}</h4>
                      </OrganizationListItem>
                    ))}
                    <RemoveSpan
                      onClick={() => {
                        if (currentValue.organization) {
                          toggleOrganization(null);
                        }
                      }}
                    >
                      Remove
                    </RemoveSpan>
                  </UL>
                </Card>
              )}
            </div>
          </>
        )}
        {localValue === 'handpicked' && (
          <>
            <H3 style={{ marginTop: 16 }}>Select the restaurants you want to see in this page block.</H3>
            <div style={{ padding: `8px 0`, marginBottom: 8 }}>
              <InputStyle
                placeholder={'Type to search for a Restaurant'}
                type={'text'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div style={{ marginBottom: 16 }}>
              {isLoading ? (
                <Spinner />
              ) : data.length === 0 ? (
                <p
                  style={{
                    opacity: 0.6,
                    fontStyle: 'italic',
                  }}
                >
                  Start typing to get some results
                </p>
              ) : (
                unselectedRestaurants.map((r) => (
                  <AddButton key={r.id} onClick={() => toggle(r)}>
                    Add {r.name}
                  </AddButton>
                ))
              )}
            </div>
            <H3>Selected restaurants:</H3>
            <div style={{ padding: `8px 0`, marginTop: 16 }}>
              <UL>
                {currentValue.restaurants.map((r) => (
                  <li key={r.id}>
                    {r.name}
                    <RemoveSpan onClick={() => toggle(r)}>Remove</RemoveSpan>
                  </li>
                ))}
              </UL>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const WrapField = ({ categories }: { categories: FilterWithNameModel[] }) => {
  const { value, onChange } = useFieldExtension();

  return <RestaurantCategoryField value={value} onChange={onChange} categories={categories} />;
};

export const RestaurantCategory = () => {
  const { isLoading, categories } = useFetchCategories();
  const queryParams = new URLSearchParams(window.location.search);
  const uid = queryParams.get('extensionUid') ?? '';

  return (
    <Wrapper uid={uid} declaration={declaration}>
      {isLoading ? <Spinner /> : <WrapField categories={categories} />}
    </Wrapper>
  );
};

export const RestaurantCategoryDev = () => {
  const [restaurants, setRestaurants] = useState<ExtendedMarketplaceRestaurantsRequest>({
    id: 'monthly',
    restaurantIds: [],
    restaurants: [],
    organization: null,
  });
  const { isLoading, categories } = useFetchCategories();

  if (isLoading) {
    return <Spinner />;
  }

  return <RestaurantCategoryField categories={categories} value={restaurants} onChange={setRestaurants} />;
};
