import styled from 'styled-components';

export const CardContainer = styled.div`
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
  transition:
    transform 0.2s,
    box-shadow 0.2s;

  &:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`;

export const CardTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
`;

export const CardContent = styled.div`
  font-size: 1rem;
  color: #666;
`;

export const OrganizationListItem = styled.li`
  background-color: #f0e9ff;
  margin-bottom: 10px;
  list-style: none;
  color: #23188d;
  align-self: center;
`;
