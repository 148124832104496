import React from 'react';
import { SelectStyle } from './Components.style';
import { DineoutRegion } from 'dineout-hygraphcms/types/graphTypes';

interface Props {
  value: string;
  onChange: (country: DineoutRegion) => void;
}

export const CountrySelector = ({ value, onChange }: Props) => (
  <SelectStyle defaultValue={value} onChange={(e) => onChange(e.target.value as DineoutRegion)}>
    <option value={'is'}>Iceland</option>
    <option value={'dk'}>Denmark</option>
  </SelectStyle>
);
