import React, { useCallback, useState } from 'react';
import {
  ExtensionDeclaration,
  FieldExtensionType,
  FieldExtensionFeature,
  useFieldExtension,
  Wrapper,
} from '@graphcms/uix-react-sdk';
import { useFetchCategories } from '../hooks/useFetchCategories';
import { Spinner } from '../components/Spinner';
import { AddButtonExtended, H3, RemoveSpan, UL } from 'src/components/Components.style';
import { FilterWithNameModel } from 'dineout-types/Filter/FilterModel';

const declaration: ExtensionDeclaration = {
  name: 'Category Selector',
  extensionType: 'field',
  fieldType: FieldExtensionType.JSON,
  features: [FieldExtensionFeature.FieldRenderer, FieldExtensionFeature.TableRenderer],
};

export const CategorySelectorField = ({
  value,
  onChange,
  categories,
}: {
  value: string[];
  onChange: (newValue: string[]) => void;
  categories: FilterWithNameModel[];
}) => {
  const toggleCategory = useCallback(
    (categoryId: string) => {
      if (onChange && typeof onChange === 'function') {
        let updatedCategories = value === null ? [] : [...value];

        if (updatedCategories.includes(categoryId)) {
          updatedCategories = updatedCategories.filter((id) => id !== categoryId);
        } else {
          updatedCategories.push(categoryId);
        }

        onChange(updatedCategories);
      }
    },
    [onChange, value],
  );

  return (
    <>
      <div style={{ padding: 8 }}>
        <H3>Select Categories</H3>
        <div style={{ padding: `8px 0`, marginTop: 16 }}>
          {Array.isArray(categories) && categories.length > 0 && (
            <>
              {categories.map((category) => {
                return (
                  <AddButtonExtended
                    key={category.id}
                    onClick={() => toggleCategory(category.id.toString())}
                    isSelected={value && value.includes(category.id.toString())}
                  >
                    {category.name}
                  </AddButtonExtended>
                );
              })}
            </>
          )}
        </div>
        <H3>Selected Categories:</H3>
        <UL>
          {Array.isArray(value) && value.length > 0 && (
            <>
              {value.map((categoryId) => {
                const category = categories.find((c) => c.id.toString() === categoryId);
                return category ? (
                  <li key={categoryId}>
                    {category.name}
                    <RemoveSpan onClick={() => toggleCategory(categoryId)}>Remove</RemoveSpan>
                  </li>
                ) : null;
              })}
            </>
          )}
        </UL>
      </div>
    </>
  );
};

export const WrapField = ({ categories }: { categories: FilterWithNameModel[] }) => {
  const { value, onChange } = useFieldExtension();

  return <CategorySelectorField value={value} onChange={onChange} categories={categories} />;
};

export const CategorySelector = () => {
  const { isLoading, categories } = useFetchCategories();
  const queryParams = new URLSearchParams(window.location.search);
  const uid = queryParams.get('extensionUid') ?? '';

  return (
    <Wrapper uid={uid} declaration={declaration}>
      {isLoading ? <Spinner /> : <WrapField categories={categories} />}
    </Wrapper>
  );
};

export const CategorySelectorDev = () => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const { isLoading, categories } = useFetchCategories();

  if (isLoading) {
    return <Spinner />;
  }

  return <CategorySelectorField categories={categories} value={selectedCategories} onChange={setSelectedCategories} />;
};
