import styled, { css } from 'styled-components';

export const ContainerBase = css`
  background-color: rgb(255, 255, 255);
  transition: border-color 0.25s ease 0s;
  box-sizing: border-box;
  min-width: 0px;
  appearance: none;
  width: 100%;
  border: 1px solid rgb(208, 212, 224);
  border-radius: 2px;
  padding-left: 12px;
  padding-right: 12px;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji';
  font-size: 15px;
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px;
  line-height: 20px;
  resize: vertical;

  &:focus {
    border: 1px solid #ab5aff;
  }
`;

export const SelectStyle = styled.select`
  ${ContainerBase};
  height: 40px;
  cursor: pointer;
`;

export const InputStyle = styled.input`
  ${ContainerBase};
  height: 40px;
  cursor: pointer;
`;

export const H3 = styled.h3`
  margin: 0;
`;

export const AddButton = styled.button`
  outline: none;
  color: #23188d;
  border: 1px solid #ab5aff;
  background: #f0e9ff;
  margin-inline: 6px 0px;
  margin-top: 6px;
  border-radius: 8px;
  padding: 8px 17px;
  font-weight: bold;
  transition: all 0.2s ease-in;
  transform: scale(1);

  &:hover {
    border: 1px solid #8b41d8;
    background: #c2bad3;
  }

  &:active {
    transform: scale(0.97865);
  }
`;

export const AddButtonExtended = styled(AddButton).withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<{ isSelected: boolean }>`
  background: ${({ isSelected }) => (isSelected ? '#9266ef' : '#f0e9ff')};
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#23188d')};
  cursor: pointer;
  &:hover {
    background: ${({ isSelected }) => (isSelected ? '#6f49be' : '#d8c9f6')};
  }
`;

export const RemoveSpan = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 8px;
    cursor: pointer;
    font-size: 11px;
    text-transform: uppercase;
    color: #c0074d;\
    display: block;
    height: 40px;
    line-height: 40px;
`;

export const UL = styled.ul`
  margin: 0;
  padding: 0;
  justify-content: center;
  align-content: center;

  li {
    list-style-type: none;
    display: block;
    width: 100%;
    ${ContainerBase};
    font-size: 14px;
    height: 40px;
    position: relative;
    user-select: none;
    margin: 6px 0;
  }
`;

export const GridItem = styled.div`
  position: relative;
  ${ContainerBase};
  padding: 12px;
  height: 126px;

  h4 {
    display: block;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
